/* Loader.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff; /* Tło strony */
}

.spinner {
  position: relative;
  width: 150px;
  height: 150px;
}

.spinner img {
  width: 100%;
  height: 100%;
  animation: spin 2s linear infinite; /* Obracaj obraz */
}

.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* Obracanie elementu */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
