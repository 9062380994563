@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: Poppins;
  margin: 0;
  background-color: #010101;
  color: #eee;
}
svg {
  width: 25px;
}
header {
  width: 1200px;
  max-width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-template-rows: 50px;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
}
header .logo {
  font-weight: bold;
}
header .menu {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
  font-weight: 500;
}
.mail {
  display: flex;
  font-size: 10px;
  color: gray;
}
.mail a {
  text-decoration: none;
  color: gray;
  font-size: 10px;
}
/* css slider */
.slider {
  height: 100vh;
  margin-top: -50px;
  position: relative;
  overflow: hidden;
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center;
  opacity: 0; /* Ustawiamy domyślnie na 0 */
  transition: opacity 0.5s; /* Dodajemy przejście dla opacity */
}

.slider .list .item {
  position: absolute;
  inset: 0 0 0 0;
  overflow: hidden;
  opacity: 0;
  z-index: 1;
}
.slider .list .item img#imgslider {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
  z-index: 0;
}
.slider .list .item::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  opacity: 1; /* Ustaw opacity na 1, aby był widoczny */
  background-image: linear-gradient(to top, #000 40%, transparent);
}
.slider .list .item .content {
  position: absolute;
  left: 15%;
  top: 13%;
  width: 570px;
  max-width: 75%;
  z-index: 2;
}
.pic {
  position: absolute;
  opacity: 1;
  left: 60%;
  top: 12%;
  width: 400px;
  height: 400px;
  -webkit-box-shadow: 0px 1px 14px 8px rgb(20, 21, 31);
  -moz-box-shadow: 0px 1px 14px 8px rgb(20, 21, 31);
  box-shadow: 0px 1px 14px 8px rgb(20, 21, 31);
  z-index: 3;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slider .list .item .content p:nth-child(1) {
  text-transform: uppercase;
  letter-spacing: 10px;
}
.slider .list .item .content h2 {
  font-size: 70px;
  line-height: 80px;
  padding-top: 35px;
  margin: 0;
}
.slider .list .item .content p {
  padding-top: 35px;
}
.slider .list .item.active {
  opacity: 1;
  z-index: 10;
}
@keyframes showContent {
  to {
    transform: translateY(0);
    filter: blur(0);
    opacity: 1;
  }
}
.slider .list .item.active p:nth-child(1),
.slider .list .item.active h2,
.slider .list .item.active p:nth-child(3) {
  transform: translateY(30px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent 0.5s 0.7s ease-in-out 1 forwards;
}
.slider .list .item.active h2 {
  animation-delay: 1s;
}
.slider .list .item.active p:nth-child(3) {
  animation-duration: 1s;
}
@keyframes neon-blink {
  34% {
    opacity: 1;
  }

  45% {
    opacity: 0.7;
  }

  46% {
    opacity: 1;
  }

  98% {
    opacity: 1;
  }

  99% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
@keyframes glow {
  from {
    text-shadow: 0 0 5px #fff, 0 0 6px #fff, 0 0 8px #009407, 0 0 12px #1e8b02,
      0 0 16px #078b19, 0 0 25px #ffffff, 0 0 30px #a5ffa1;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 6px #7fff4d, 0 0 8px #5cff4d,
      0 0 12px #62ff4d, 0 0 16px #53ff4d, 0 0 25px #4dff4d, 0 0 30px #62ff4d;
  }
}
.arrows {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 95px;
  top: 65%;
  right: 50%;
  z-index: 60;
  left: 0;
  right: 0;
}
.arrows button {
  background: none;
  border: none;
  color: #eee;
  z-index: 6;
  width: 100px;
  height: 35px;
  font-size: 28px;
  transition: all 0.5s;
  font-family: dzikaswinia;
}
.arrows button:hover {
  transition: all 0.5s;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  cursor: pointer;
}

/*ssssssssssssssssssssssssssssssssss */
.slider {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px; /* Odstęp między sliderem a miniaturkami */
}

/*ssssssssssssssssssssssssssssssssss */

.welcome-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #000000;
}

.logo {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: dzikaswinia;
  cursor: pointer;
  color: #fff;
  opacity: 0; /* Domyślnie niewidoczne */
  filter: blur(20px); /* Początkowy blur */
  transition: filter 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Przejścia dla hover */
}

.logo.animated {
  opacity: 1; /* Ustaw widoczność */
  filter: blur(0); /* Usuń blur */
  animation: showContent 0.5s ease-in-out 1 forwards; /* Jednorazowa animacja */
}
.logo:hover {
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
.start-button {
  font-size: 24px;
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
}

@media screen and (max-width: 678px) {
  .slider .list .item .content h2 {
    position: unset;
    font-size: 40px;
    line-height: 50px;
    padding-top: 10px;
  }
  .slider .list .item {
    margin-top: 40px;
  }
  .item {
    display: flex;
    align-items: center;
    padding-top: 20px;
    flex-direction: column;
    position: unset;
    z-index: 5;
    gap: 20px;
  }

  .slider .list .item .content p {
    font-size: 14px;
    padding-top: 20px;
  }
  .slider .list .item .content {
    position: unset;
    z-index: 5;
    top: 5%;
    padding-top: 10px;
  }
  .arrows {
    width: 90px;
    height: 90px;
    top: 60%;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    gap: 50px;
  }
  .arrows button {
    width: 100px;
    height: 35px;
    font-size: 20px;
  }
  .pic {
    margin-top: 20px;
    position: unset;
    opacity: 1;
    width: 280px;
    height: 280px;
  }
}
@media screen and (min-width: 679px) and (max-width: 1280px) {
  .slider .list .item .content h2 {
    font-size: 50px;
    text-align: left;
    line-height: 60px;
    padding-top: 40px;
  }
  .slider .list .item .content p {
    font-size: 13px;
    text-align: left;
    padding-top: 40px;
  }
  .slider .list .item .content {
    left: 10%;
    top: 10%;
    width: 400px;
    max-width: 40%;
    z-index: 2;
  }
  .arrows {
    top: 45%;
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    width: 110px;
    gap: 60px;
  }
  .arrows button {
    width: 100px;
    height: 35px;
    font-size: 24px;
  }
  .pic {
    top: 15%;
    margin: auto;
    left: 52%;
    width: 320px;
    height: 320px;
    margin-right: 10px;
  }
}
@media screen and (min-width: 1920px) {
  .slider .list .item .content {
    left: 22%;
    top: 14%;
    width: 600px;
    max-width: 40%;
    z-index: 2;
  }
}
