* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}
@font-face {
  font-family: dzikaswinia;
  src: url("../src/fonts/crazy.TTF") format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*
.relative {
  position: relative;
  width: 100%;
  height: 100vh;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@keyframes neon-blink {
  34% {
    opacity: 1;
  }

  45% {
    opacity: 0.7;
  }

  46% {
    opacity: 1;
  }

  98% {
    opacity: 1;
  }

  99% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
#logo {
  position: absolute;
  z-index: 2;
  font-family: dzikaswinia;
  color: #fff;
  font-size: 40px;
  top: 50%;
  width: calc(100% - 70%);
  margin-left: 35%;
  margin-right: 35%;
  text-align: center;
  animation: neon-blink 5s infinite alternate;
}
@media only screen and (min-width: 1280px) {
  #logo {
    width: calc(100% - 80%);
    margin-left: 40%;
    margin-right: 40%;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1279px) {
  #logo {
    width: calc(100% - 74%);
    margin-left: 37%;
    margin-right: 37%;
  }
}
@media only screen and (max-width: 900px) {
  #logo {
    width: calc(100% - 70%);
    margin-left: 35%;
    margin-right: 35%;
  }
}
.logo_hover {
  position: absolute;
  z-index: 4;
  font-family: dzikaswinia;
  color: #fff;
  font-size: 40px;
  top: 50%;
  width: calc(100% - 70%);
  margin-left: 35%;
  margin-right: 35%;
  text-align: center;
  opacity: 0;
  transition: all 0.3s;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
@media only screen and (min-width: 1280px) {
  .logo_hover {
    width: calc(100% - 80%);
    margin-left: 40%;
    margin-right: 40%;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1279px) {
  .logo_hover {
    width: calc(100% - 74%);
    margin-left: 37%;
    margin-right: 37%;
  }
}
@media only screen and (max-width: 900px) {
  .logo_hover {
    width: calc(100% - 70%);
    margin-left: 35%;
    margin-right: 35%;
  }
}
@keyframes glow {
  from {
    text-shadow: 0 0 5px #fff, 0 0 6px #fff, 0 0 8px #009407, 0 0 12px #1e8b02,
      0 0 16px #078b19, 0 0 25px #ffffff, 0 0 30px #a5ffa1;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 6px #7fff4d, 0 0 8px #5cff4d,
      0 0 12px #62ff4d, 0 0 16px #53ff4d, 0 0 25px #4dff4d, 0 0 30px #62ff4d;
  }
}

.logo2 {
  position: absolute;
  z-index: 4;
  font-family: dzikaswinia;
  color: #fff;
  font-size: 40px;
  top: 50%;
  width: calc(100% - 70%);
  margin-left: 35%;
  margin-right: 35%;
  text-align: center;
  opacity: 1;
  transition: all 0.3s;
  cursor: pointer;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
@media only screen and (min-width: 1280px) {
  .logo2 {
    width: calc(100% - 80%);
    margin-left: 40%;
    margin-right: 40%;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1279px) {
  .logo2 {
    width: calc(100% - 74%);
    margin-left: 37%;
    margin-right: 37%;
  }
}
@media only screen and (max-width: 900px) {
  .logo2 {
    width: calc(100% - 70%);
    margin-left: 35%;
    margin-right: 35%;
  }
}
#title2 {
  position: absolute;
  top: 48%;
  left: 55%;
  font-size: 22px;
  color: #fff;
}
#button {
  position: absolute;
  top: 58%;
  left: 45%;
  font-size: 30px;
  cursor: pointer;
}
.particles-js {
  z-index: 2;
  position: relative;
  height: 100vh;
  background: #000;
  mix-blend-mode: darken;
}
*/
